<template>
  <!-- 物联网卡管理 -->
  <div class="gateWayWareHoushimg">
    <!-- 板块中心区域 -->
    <div class="gateway_mid">
      <div class="gateway_header">
        <!-- 标题 -->
        <div class="title">
          <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="">
          <p v-for="(item,index) in $route.meta" :key="index">{{ item }}</p>
        </div>
        <!-- 选项框和按钮 -->
        <div class="option_btn">
          <div class="options">
            <!-- Element ui--Select 选择器 -->
            <!-- 网关编号 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">网卡编号</span>
              <el-select v-model="value" placeholder="请选择" style="margin:0 1.45vw 0 0">
                <el-option v-for="item in options1" :key="item.id" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template >
          </div>
          <!-- Element UI--Button按钮 -->
          <div class="btns">
            <el-button size="mini" @click="multShipObj.isshow = true">批量入库</el-button>
            <el-button type="primary" size="mini" style="margin-right: 1vw;background-color: #0666F5;" @click="formsObj.isshow = true">网卡入库</el-button >
          </div>
        </div>
      </div>

      <!-- 表格区域 -->
      <div class="gateway_form">
        <!-- 列表页 -->
        <lists></lists>
        <!-- 表单页 -->
        <forms :formsObj="formsObj"></forms>
        <!-- 批量入库 -->
        <multship :multShipObj="multShipObj"></multship>
      </div>
    </div>
  </div>
</template>

<script>
// 引入物联网卡入库--列表页
import lists from "./components/lists.vue";
// 引入物联网卡入库--表单页
import forms from "./components/forms.vue";
// 引入批量入库
import multship from "./components/multship";

// 引入vuex的辅助函数
import { mapActions, mapGetters,mapState,mapMutations } from 'vuex';
export default {

  components: {
    lists,//列表页
    forms,//表单页
    multship,//批量入库
  },
  data () {
    return {
       value:'',//网卡编号的value
    };
  },
  computed:{
    ...mapState('nicManage',['options1','formsObj','multShipObj'])
  },
  mounted () {
    
  },

  methods: {

  },
};
</script>

<style  scoped>
.gateWayWareHoushimg .gateway_mid {
  width: 82.5vw;
  height: 85vh;
}
/* 头部--开始 */
.gateway_mid .gateway_header {
  width: 100%;
  height: 12.4vh;
  background-color: #fff;
}
.gateway_header .title {
  height: 3.33;
  display: flex;
}
.gateway_header .title img {
  width: 6px;
  height: 2.4vh;
  margin: 1vw 0 0 1vw;
}
.gateway_header .title p {
  font-size: 20px;
  font-family: "SHSCN_Bold";
  margin: 1vw 0 0 0.6vw;
}
/* 选项框和按钮 */
.option_btn {
  width: 100%;
  height: 7.2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option_btn /deep/ .el-input{
  width: 10.7vw;
  height: 2.96vh;
}
.options{
  height: 7.2vh;
  display: flex;
  align-items: center;
}
.option_btn /deep/ input{
  width: 10.7vw;
  height: 2.96vh;
}
.option_btn /deep/ .el-input__icon{
  line-height: 1.85vh ;
}
.option_btn span{
  font-size: 16px;
  font-family: 'SHSCN_Regular';
}
.btns{
  margin-top: 26px;
}
/* 头部--结束 */

/* 表格区域--开始 */
.gateway_mid .gateway_form {
  width: 100%;
  height: 71.8vh;
  background-color: #fff;
}
/* 表格区域--结束 */
</style>