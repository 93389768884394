<template>
  <!-- 网关入库--表单页 -->
  <div class="forms">
    <!-- Element ui -- Dialog对话框 -->
    <el-dialog title="网卡入库" :visible.sync="formsObj.isshow" :close-on-click-modal="false">
      <el-form :model="form" ref="form" :rules="rules">
        <!-- 运营商 -->
        <el-form-item label="运营商" :label-width="formLabelWidth" class="operators" prop="operators">
          <el-select v-model="form.operators" placeholder="请选择运营商">
            <el-option :label="item.label" :value="item.value" v-for="item in operatorsArr" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- 套餐 -->
        <el-form-item label="套餐" :label-width="formLabelWidth" class="combo" prop="combo">
          <el-select v-model="form.combo" placeholder="请选择套餐类型">
            <el-option :label="item.label" :value="item.value" v-for="item in comboArr" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- 通信方式 -->
        <el-form-item label="通信方式" :label-width="formLabelWidth" prop="communiateMethod" class="acMethod">
          <el-select v-model="form.communiateMethod" placeholder="请选择通讯方式">
            <el-option :label="item.label" :value="item.value" v-for="item in acModthArr" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- 采购日期 -->
        <el-form-item label="采购日期" :label-width="formLabelWidth" prop="purchaseData" class="purchase">
          <el-date-picker v-model="form.purchaseData" type="date"
        placeholder="选择日期" style="width:260px;heigth:32px"></el-date-picker>
        </el-form-item>
        <!-- 网卡编号 -->
        <el-form-item label="网卡编号" :label-width="formLabelWidth" prop="numbering" class="gateNumbering">
          <el-input v-model="form.numbering" autocomplete="off" placeholder="请输入网卡编号" style="width:260px;heigth:32px"></el-input>
        </el-form-item>
        <!-- 添加按钮 -->
        <el-form-item class="button_box">
          <el-button size="mini" style="background-color:#0666F5;color: #fff;width: 4.17vw;height: 4.45vh;">添加
          </el-button>
        </el-form-item>
        <!-- 多个添加网关设备 -->
        <el-form-item>
          <div class="mult_networkCardAdd">
            <!-- 最多十个 -->
            <p  style="margin:0 0 0 0.43vw;" v-for="item in multDeice" :key="item.id">{{item.numbering}}<i class="el-icon-remove" style="color:#C1C3C7;margin-right: 1.675vw;padding-left: 0.3vw;" ref="delNetWork"></i></p>
          </div>
          <p class="mult_tip">*最多可添加10个网关设备</p>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('form')">重 置</el-button>
        <el-button type="primary" @click="formsObj.isshow = false">确 定</el-button>
      </div>
      
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
export default {
  props:["formsObj"],
  data () {
    return {
      formLabelWidth: '80px'
    };
  },
  computed: {
    ...mapState('nicManage', ['multDeice',"form",'operatorsArr','rules','acModthArr','comboArr'])
  },

  mounted () {
    
  },
  updated(){
    this.add()
  },

  methods: {
    add(){
      let multDeice = this.multDeice
      let delNetWork = this.$refs.delNetWork
      for(let i = 0;i < delNetWork.length;i++){
        delNetWork[i].tag = true;
        delNetWork[i].onclick = function(){
          if(delNetWork[i].tag){
            this.style.color = '#DF2626'
            this.tag = false;
            // 并删除数据
            delNetWork[i].onclick = function(event){
                this.style.color = '#C1C3C7'
                event.stopPropagation()
                // 并删除数据
                multDeice.splice(i, 1);
                if(multDeice.length <= 0){
                  msg('已经没有网关了,请先添加','error')
                }
                event.stopPropagation()
              }
          }else{
            this.style.color = '#C1C3C7'
            this.tag = true
          }
        }
      }
    },
    //重置表单
      // 重置表单
  resetForm(formName) {
    this.$refs[formName].resetFields();
    this.formsObj.isshow = false
  }
  },
};
</script>

<style  scoped>
.forms {
  width: 0.4vw;
  height: 0.453vh;
}
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: "SHSCN_Regular";
}

.el-select {
  width: 14.33vw !important;
  height: 4.45vh !important;
}
.el-select /deep/ .el-input__inner {
  height: 4.45vh;
}
.el-input /deep/ input {
  /* width: 14.33vw; */
  height: 4.45vh;
}


/* 按钮外部的尺寸 */
.button_box /deep/ .el-form-item__content {
  width: 18.63vw;
  height: 4.45vh;
  margin-left: 0px;
}
.el-form-item /deep/ .el-button {
  font-family: "SHSCN_Regular";
}
/* 多个网关添加 */
.el-form-item .mult_networkCardAdd {
  width: 649px;
  height: 132px;
  border: 1px solid #c1c3c7;
  border-radius: 5px 5px 5px 5px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-left: 100px;
  /* justify-content: center; */
  /* justify-items: center; */
}
.el-form-item .mult_tip {
  font-size: 12px;
  font-family: "SHSCN_Regular";
  color: #707070;
  margin-left: 5.2vw;
}
/* 通信方式 */
.acMethod{
  margin: 0 0 22px 0 ;
}
.purchase{
  margin: 0 0 22px 20px ;
}
.gateNumbering{
  margin: 0 20px 22px 0;
}

</style>