<template>
  <div class="multShip">
    <!-- 批量入库 -->
    <!-- Element ui的dialog弹窗 -->
    <el-dialog title="批量入库" :visible.sync="multShipObj.isshow" :close-on-click-modal="false">
      <!-- Element ui的文件上传 -->
      <el-upload class="upload-demo" drag multiple :on-change="upLoadFile" action="#" :auto-upload=false>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传Excel文件，且不超过2M</div>
      </el-upload>
      <!-- 确定按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="multShipObj.isshow = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入消息提示
import { msg } from "../../../common/public";
// 引入辅助函数
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
export default {
  props: ['multShipObj'],
  data () {
    return {

    };
  },
  computed: {
    ...mapState('nicManage',[])
  },
  mounted () {

  },

  methods: {
    // 文件上传
    upLoadFile (file) {
      let { size, type } = file.raw;
      console.log(size, type);
      // 1.判断文件大小
      if (size > 2 * 1024 * 1024) { //Excel文件大小不得超过2M
        msg('文件大小不能超过2M', 'warning');
        return false;
      }
      //判断文件格式是否为excel文件
      let typeArr = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 'application/vnd.ms-excel']
      if (typeArr.indexOf(type) == -1) {
        msg('只能上传Excel文件', 'warning');
        return false;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .el-dialog__title {
  color: #171717;
  font-family: "SHSCN_Bold";
  line-height: 18px;
}
.el-dialog__body .upload-demo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>