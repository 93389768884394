<template>
  <!-- 物联网卡入口与-- 表格区域 -->
  <div class="lists">
    <!-- Element ui -- Form表单 -->
    <template>
      <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName"
       :header-cell-style="{background:'#4888FF'}" height="600">
        <!-- 索引序号 -->
        <el-table-column type="index" width="100"></el-table-column>
        <!-- 网卡编号 -->
        <el-table-column prop="numbering" label="网卡编号">
        </el-table-column>
        <!-- 运营商 -->
        <el-table-column prop="operators" label="运营商">
        </el-table-column>
        <!-- 套餐 -->
        <el-table-column prop="combo" label="套餐">
        </el-table-column>
        <!-- 采购日期 -->
        <el-table-column prop="purchaseData" label="采购日期">
        </el-table-column>
        <!-- 入库时间 -->
        <el-table-column prop="inboundTime" label="入库时间" sortable >
        </el-table-column>
        <!-- 退库 -->
        <el-table-column label="操作">
          <template>
            <el-link type="primary" @click="withDrawObj.isshow = true">退库</el-link>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- Element ui--Pagination分页 -->
    <template>
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
        :current-page="currentPage" :page-sizes="[10]" :page-size="100" layout="total, prev, pager, next,sizes,jumper"
        :total="100">
      </el-pagination>
    </template>
    <!-- 退库 -->
    <withdrawapplication :withDrawObj="withDrawObj"></withdrawapplication>
  </div>
</template>

<script>
// 引入vuex的辅助函数
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
// 引入网卡入库的退库
import withdrawapplication from "./withDrawApplication.vue";
export default {
  components: {
    withdrawapplication
  },

  data () {
    return {

    };
  },
  computed: {
    ...mapState('nicManage', ['tableData', 'currentPage', "agreeApplication",'withDrawObj'])
  },
  mounted () {
  },

  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 1) {
        return 'success-row';
      }
      return '';
    },
    // 分页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style  scoped>
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}
.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}
.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-weight: normal;
  font-size: 14px;
}
.lists .el-pagination {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Source Han Sans CN-Regular";
}
.lists /deep/ .el-pagination__jump {
  margin-left: 0;
}
::v-deep .descending .sort-caret.descending {
  border-top-color: #fff;
}
.el-table /deep/ .el-table__cell{
  text-align: center;
  padding: 16px 0;
}
</style>